.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.cus-ml {
  margin-left: 30rem;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.underMaintainance-container,
.center-container {
  width: 100%;
  height: 100vh;
  /* Set height to 100% of the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.underMaintainance-container .text1,
.centered-text {
  color: #bb2e2c;
  text-shadow: -1px 1px 5px #ffe7b6;
}
.expenseAddRowRequiredSymbol::before {
  color: #ff4d4f;
  font-size: 14px;
  margin-inline-end: 4px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

span.customDisabled {
  opacity: 1;
  background-color: #f3f6f975 !important;
  color: #00000091 !important;
  font-weight: 600 !important;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
}
.notificationPanel.tab-content {
  overflow-y: auto;
  max-height: 88vh;
}

li.dExpenseReportList:focus-visible {
  outline: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
