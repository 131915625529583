.login_box {
  box-sizing: border-box;
  max-width: 500px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.sign_in {
  background: #168ce2;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 15px;
  font-weight: 600;
  text-shadow: none;
  display: flex;
  justify-content: center;
  color: #fff !important;
  cursor: pointer;
}

.login-page-logo {
  justify-content: center;
  margin-bottom: 2rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.login-wrap {
  border: 1px solid #e8e8e8;
  padding: 5rem;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(13, 25, 36, 0.06);
  border-radius: 5px;
}

.separator {
  width: 0px;
  height: 68.5px; /*45.5px; */
  /* color-border */
  border: 1px solid #e0e0e0;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.expense_logo {
  /* width: 79px;
  height: 56px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: #9c9fa0;
  flex: none;
  order: 2;
  flex-grow: 0; */

  width: 264px;
    height: 78px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #9c9fa0;
    flex: none;
    order: 2;
    flex-grow: 0;
}