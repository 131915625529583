/***NAVBAR START******/
.logo-area {
display: flex;
}

.logo-img {
width: 150px;
margin-right: 5px;
}

.horizontal_line.navbarline {
width: 100%;
height: auto;
order: unset;
}

.auditsText{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
color: #9C9FA0;
}
.searchInput { font-weight: 500; font-size: 16px; color: #9C9FA0; align-items: center; box-sizing: border-box; display: flex; flex-direction: row; gap: 10px; padding: 10px; width: 100%;
}
.searchInput input {
border: none;
outline: none;
}
.navbarHoriz { 
display: flex;
}
.namelinkclass{
font-weight: 600;
font-size: 20px;
color: #152327;
}
.main-sign-out {
color: #ffffff;
background: #b22d2b;
border: none;
font-size: 16px;
font-weight: 600;
border-radius: 5.5px;
padding: 10px 14px;
line-height: normal;
height: auto;
}
.top-header {
display: flex;
justify-content: space-between;
padding: 10px 20px;
align-items: center;
border-bottom: 0.5px solid #E0E0E0;
}
.name_search {
    margin: 0 auto 0 3rem;
}
.search-area {
    border-left: 2px solid #E0E0E0;
}

.sidenav {
position: relative;
box-sizing: border-box;
width: 280px;
background: #FBFBFB;
float: left;
}
.sidenav::after {
    content: "";
    position: absolute;
    top: -20vh;
    width: 1px;
    background: #E0E0E0;
    right: 0;
    bottom: 0;
    margin: auto;
}


.sidenav a.links {
padding: 15px 0px 15px 30px;
display: block;
}
.sidenav a.links img,.sidenav a.links i {
margin: -4px 10px 0 0;
}
.dashboardstyle{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #000000;
transition: all 0.3s;
}
.dashboardstyle:hover {
    color: #b22d2b;
}
.margin-top {
margin-top: 30px;
}

.dropwdown {
    height: 0;
    overflow: hidden;
}
.menu .d-block.links{font-size: 20px;    font-weight: bold; padding: 14px 28px 14px 29px;cursor:pointer;}
.menuActive .d-block.links{    background: #b22d2b;    /* margin: 10px 0; */    color: #fff;       }
.menu .d-block.links i.fa{ float:right; }
a.links i{    font-size: 20px;    color: #000;    margin: 0 10px;}
/***NAVBAR END******/